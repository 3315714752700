<template>
  <div>
    <div class="title_div">
      <img
        src="@/assets/icon_2/fanhui.png"
        style="height:20px;width:20px;margin-left:10px;"
        @click="goBack()"
      />
      <div>早报</div>
      <div style="width:40px;"></div>
    </div>
    <!--列表-->
    <div v-if="pagetype == 1">
      <div
        v-for="(item, index) in newsList"
        :key="index"
        class="every_option"
        @click="chooseOption(item)"
      >
        <div class="two_line">{{ item.title }}</div>
        <img
          img
          src="@/assets/icon/fanhui.png"
          style="height:4vw;width:4vw;margin-left:4vw;"
        />
      </div>
    </div>
    <div v-if="pagetype == 2">
      <div style="font-size:18px;font-weight:800;margin:2vh 5vw;">
        {{ choose_item.title }}
      </div>
      <div style="text-align:right;margin-right:5vw;color:#A3A3A3;">
        {{ choose_item.author }}
      </div>
      <div
        style="text-align:right;margin-right:5vw;color:#A3A3A3;margin-top:5px;"
      >
        {{ choose_item.publishTime }}
      </div>
      <div style="display:flex;justify-content:center;margin-top:10px;">
        <img :src="choose_item.firstImgUrl" style="max-width:90vw;" />
      </div>
      <div style="font-size:14px;margin:2vh 5vw;">
        {{ choose_item.content }}
      </div>
      <div style="display:flex;justify-content:center;">
        <img :src="choose_item.img" style="max-width:90vw;" />
      </div>
      <div style="height:100px;"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      newsList: [],
      pagetype: 1,
      choose_item: {}, //用户选择的列表
    };
  },
  created() {
    this.getNewsList();
  },
  methods: {
    //获取业务流程列表
    getProcessList() {
      this.$axios.get(this.$api.getProcessList, {}).then((res) => {
        console.log(res, "业务流程");
        let processList = res.data.list;
      });
    },
    goBack() {
      if (this.pagetype == 1) {
        this.$router.go(-1);
      } else {
        this.pagetype = 1;
      }
    },
    //获取早报列表
    getNewsList() {
      
      this.$axios
        .post(this.$api.getNewsList, {
        
        })
        .then((res) => {
          console.log(res, "新增那个");
          this.newsList = res.data.page.list;
          console.log(this.newsList, "zaobao");
        });
    },
    //选择队列
    chooseOption(item) {
      console.log(item,'123123123123');
      this.getNewsList(item.id);
      this.choose_item = item;
      console.log(this.choose_item, "this.choose_item");
      this.pagetype = 2;
    },
  },
};
</script>
<style scoped>
.title_div {
  height: 8vh;
  background-image: linear-gradient(#3f98eb, #006ed5);
  /* background: #3F98EB; */
  color: #ffffff;
  font-size: 4.6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.every_option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  font-size: 14px;
  padding: 2vh 4vw;
}
.two_line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
